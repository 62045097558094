import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ColorInput from "./ColorInput";

const useStyles = makeStyles((theme) => ({
  input: {
    width: 80,
    marginLeft: 12
  },
}));

const ImageNode = ({ node, onChange }) => {
  const classes = useStyles();

  const onColorChange = (val) => {
    node.borderColor = val;
    onChange(node);
  };

  const onWidthChange = (e) => {
    const { value } = e.target;
    if (value >= 0) {
      node.borderWidth = Number(value);
      onChange(node);
    }
  };

  return (
    <Box
      border={1}
      padding={1}
      marginBottom={1}
      
    >
       <Typography>Image Stroke</Typography>
       <Typography variant="caption">This is directly around the image and covers up part of the image. Normally not needed.</Typography>
      <Box marginRight={2} marginTop={2} display="flex" alignItems="center" flex={1}>
        <Typography variant="body2" style={{ marginRight: 6 }}>
          Border Color:{" "}
        </Typography>
        <ColorInput value={node.borderColor} onChange={onColorChange} />
       <TextField 
        className={classes.input}
        type="number"
        title="Image stroke thickness in pixels"
        size="small"
        min={0}
        max={300}
        onChange={onWidthChange}
        value={node.borderWidth}
        variant="outlined"
      />
    </Box> </Box>
  );
};

const TextSettings = ({ nodes, onChange }) => {
  return (
    <Box marginBottom={2}>
      {nodes.map((node, i) => (
        <ImageNode key={i} node={node} onChange={onChange} index={i} />
      ))}
    </Box>
  );
};

export default TextSettings;
