import { red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: "#1986f3",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
  },
  shape: 0,
  props: {
    MuiButton: {
      disableElevation: true,
    },
  },
  typography: {
    h1: {
      fontSize: "2.5rem",
      fontWeight: 400,
      color: "#3a3a3a",
      paddingTop: "1rem",
      paddingBottom: "1rem",
    },
    h2: {
      fontSize: "1.7rem",
      fontWeight: 500,
      color: "#3a3a3a",
      paddingTop: "1rem",
      paddingBottom: "1rem",
    },
    h3: {
      fontSize: "1.7rem",
      fontWeight: 500,
      color: "#3a3a3a",
      paddingTop: "1rem",
      paddingBottom: "1rem",
    },
    h4: {
      fontSize: "1.7rem",
      fontWeight: 500,
      color: "#3a3a3a",
      paddingTop: "1rem",
      paddingBottom: "1rem",
    },
  },
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow: "0px 5px 20px rgba(158, 158, 158, 0.2)",
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
      },
    },
    MuiLink: {
      underlineHover: {
        borderBottom: "1px dotted #909090",
        "&:hover": {
          textDecoration: "none",
          borderBottom: "1px solid transparent",
        },
      },
    },
    MuiTypography: {
      paragraph: {
        marginTop: "1.3rem",
      },
    },
  },
});

export default theme;
