import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { Helmet } from "react-helmet";

const Help = () => {
  return (
    <Box marginTop={6}>
      <Helmet title="Help - Template Generator">
        <meta
          name="description"
          content="Our free template generator is fairly powerful but does have some quirks sometimes."
        />
      </Helmet>
      <Container>
        <Typography variant="h1">Template Help</Typography>
        <Typography>Info Coming Soon!</Typography>
      </Container>
    </Box>
  );
};

export default Help;
