import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { inchToPixels } from "utils/index";

const useStyles = makeStyles((theme) => ({
  root: {
    left: ({ node, zoomRatio }) => inchToPixels((node.x - 0.15) * zoomRatio),
    top: ({ node, zoomRatio }) => inchToPixels((node.y - 0.15) * zoomRatio),
    width: ({ node, zoomRatio }) =>
      inchToPixels((node.width + 0.3) * zoomRatio),
    height: ({ node, zoomRatio }) =>
      inchToPixels((node.height + 0.3) * zoomRatio),
    textAlign: ({ node }) => node.align,
    position: "absolute",
    zIndex: 2,
    cursor: "pointer",
    "&:hover": {
      outline: "1px solid lightblue",
      outlineOffset: "-10px",
    },
  },
  text: ({ node, zoomRatio }) => ({
    fontSize: node.fontSize * zoomRatio,
    color: node.fontColor,
    fontWeight: node.fontWeight,
    fontFamily: node.fontFamily,
  }),
}));

const TextNode = ({ node, zoomRatio }) => {
  const classes = useStyles({ node, zoomRatio });
  const {
    text,
    width,
    height,
    fontFamily,
    fontSize,
    fontColor,
    fontWeight,
    rotate,
    dropShadow,
    enableShadow,
    align,
    fontStyle,
  } = node;
  const widthPx = Math.round(inchToPixels(width + 0.3));
  const heightPx = Math.round(inchToPixels(height + 0.3));

  let textX = `${45 * zoomRatio}px`;
  let textAnchor = "start";
  if (align === "center") {
    textAnchor = "middle";
    textX = "50%";
  } else if (align === "right") {
    textAnchor = "end";
    textX = `${((widthPx - 45) / widthPx) * 100}%`;
  }
  //hacky way to make rotation work on the one template that needs it. Antything other than -25 won't work, and probably has to be the same size node
  let hardCodeRotation = "";
  if (rotate === -25) {
    hardCodeRotation = "rotate(-25) translate(-25 50)";
  }

  const fontSizeZoomed = fontSize * zoomRatio;
  return (
    <Box className={classes.root}>
      <svg
        viewBox={`0 0 ${widthPx * zoomRatio} ${heightPx * zoomRatio} `}
        transform={hardCodeRotation}
      >
        {enableShadow ? (
          <g
            overflow="hidden"
            textAnchor={textAnchor}
            fontSize={fontSizeZoomed}
            fontWeight={fontWeight}
            fontStyle={fontStyle}
            fontFamily={fontFamily}
          >
            <defs>
              <filter id="shadow" x="0%" y="0%" width="200%" height="200%">
                <feGaussianBlur
                  stdDeviation={`${dropShadow} ${dropShadow}`}
                  result="shadow"
                />
                <feOffset dx="1" dy="1" />
              </filter>
            </defs>
            <text
              filter="url(#shadow)"
              fill="black"
              x={`${textX}`}
              y="50%"
              dy={`${fontSizeZoomed / 3}px`}
            >
              {text}
            </text>
            <text
              x={textX}
              y="50%"
              dy={`${fontSizeZoomed / 3}px`}
              fill={`${fontColor}`}
            >
              {text}
            </text>
          </g>
        ) : (
          <text
            x={`${textX}`}
            y="50%"
            dy={`${fontSizeZoomed / 3}px`}
            textAnchor={textAnchor}
            fontWeight={fontWeight}
            fontSize={fontSizeZoomed}
            fontFamily={fontFamily}
            fontStyle={fontStyle}
            fill={fontColor}
          >
            {text}
          </text>
        )}
      </svg>
    </Box>
  );
};

export default TextNode;
