import { useMemo, useEffect } from "react";
import Cropper from "react-cropper";
import { makeStyles } from "@material-ui/core/styles";
import { inchToPixels } from "../utils";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    "&:before": {
      content: '" "',
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      border: ({ borderColor, borderWidth, zoomRatio }) =>
        borderWidth
          ? `${borderWidth * zoomRatio}px solid ${borderColor}`
          : "none",
      pointerEvents: "none",
      zIndex: 1,
    },
  },
}));

const ImageNode = ({ node, cropperRef, fitImage, onZoom, zoomRatio }) => {
  const classes = useStyles({
    zoomRatio,
    borderWidth: node.borderWidth,
    borderColor: node.borderColor,
  });

  const imgWidth = useMemo(() => {
    const px = inchToPixels(node.width);
    return px * zoomRatio;
  }, [node]);

  const imgHeight = useMemo(() => {
    const px = inchToPixels(node.height);
    return px * zoomRatio;
  }, [node]);

  const previewImage = useMemo(() => {
    if (node.image) return URL.createObjectURL(node.image);
  }, [node.image]);

  const handleZoom = (e) => {
    const { ratio } = e.detail;
    const ppi = Math.floor(300 / (ratio / zoomRatio));
    if (ppi < 50) {
      e.preventDefault();
    } else {
      onZoom(ratio);
    }
  };

  const onReady = (e) => {
    const { cropper } = e.target;
    window.croppper = cropper;
  };

  return (
    <Cropper
      className={classes.root}
      style={{
        height: imgHeight,
        width: imgWidth,
        marginLeft: inchToPixels(node.x) * zoomRatio,
        marginTop: inchToPixels(node.y) * zoomRatio,
        overflow: "hidden",
      }}
      src={previewImage}
      autoCropArea={1}
      dragMode="move"
      viewMode={fitImage ? 2 : 3}
      rotatable={true}
      guides={true}
      center={false}
      wheelZoomRatio={0.03}
      highlight={false}
      toggleDragModeOnDblclick={false}
      cropBoxResizable={false}
      cropBoxMovable={false}
      background={false}
      modal={false}
      checkOrientation={true}
      ref={cropperRef}
      zoom={handleZoom}
      ready={onReady}
    />
  );
};

export default ImageNode;
