const DPI = 300;
export const inchToPixels = (inch) => Math.round(DPI * inch);

export const getRotationFromImage = (file, imageNode) => {
  return new Promise((resolve) => {
    const { width, height } = imageNode;
    const nodeWidth = inchToPixels(width);
    const nodeHeight = inchToPixels(height);
    const fileAsDataURL = window.URL.createObjectURL(file);
    const img = new Image();
    let imgOrientation = "vertical";
    let nodeOrientation = "vertical";
    img.onload = function () {
      const { naturalWidth, naturalHeight } = img;
      URL.revokeObjectURL(img.src);
      const nodeRatio = nodeWidth / nodeHeight;
      const imgRatio = naturalWidth / naturalHeight;
      if (nodeRatio >= 1) {
        nodeOrientation = "horizontal";
      }
      if (imgRatio >= 1) {
        imgOrientation = "horizontal";
      }
      console.log({ imgRatio, nodeRatio });
      console.log({ imgOrientation, nodeOrientation });
      console.log({ naturalWidth, naturalHeight });
      if (imgOrientation !== nodeOrientation) {
        console.log("need to rotate -90");
        resolve(-90);
      } else {
        console.log("no need to rotate");
        resolve();
      }
    };
    img.src = fileAsDataURL;
  });
};

export const getZoom = (product) => {
  if (!product) return 1;
  //console.log('getZoom function');
  //console.log('product :>> ', product);
  const { innerWidth, innerHeight } = window;
  // you have to worry about both width and height as the user could rotate the device
  const { width, height } = product;
  // what about node width and height - 7/29/2022
  //console.log(`innerWidth, innerHeight`, innerWidth, innerHeight);
  //what is the max side of the image node - width or height?
  // 7/24/2022 what if there is a border? we're checking overall template size but the ratio returned is for the size of the image node
  const maxSide = width > height ? width : height;
  const minSide = width < height ? width : height;

  const maxInnerSide = innerWidth > innerHeight ? innerWidth : innerHeight;
  const minInnerSide = innerWidth < innerHeight ? innerWidth : innerHeight;
  console.log("minInnerSide, maxInnerSide :>> ", minInnerSide, maxInnerSide);
  const maxSidePixels = inchToPixels(maxSide);

  console.log("is mobile?", maxInnerSide < 901 && minInnerSide < 450);
  //mobile devices
  if (maxInnerSide < 940 && minInnerSide < 470) {
    //what is the desired width for the ui?
    const maxWidthToReturn = 300;
    // check if maxWidth above is evenly divisible by the maxSide
    // if not, find the next highest number that is evenly divisible by the maxSide
    // by subtracting the remainder
    const actualWidthToReturn = maxWidthToReturn - (maxWidthToReturn % maxSide);
    console.log("actualWidthToReturn", actualWidthToReturn);
    return actualWidthToReturn / maxSidePixels;
  }
  //tablets
  if (maxInnerSide < 1370 && minInnerSide < 1370) {
    // make sure there is no remainder when dividing the max width by the max side of the image
    const maxWidthToReturn = 460;
    const actualWidthToReturn = maxWidthToReturn - (maxWidthToReturn % maxSide);
    console.log("actualWidthToReturn", actualWidthToReturn);
    return actualWidthToReturn / maxSidePixels;
  }
  //medium desktop
  if (maxInnerSide < 1921 && minInnerSide > 900) {
    // make sure there is no remainder when dividing the max width by the max side of the image
    const maxWidthToReturn = 550;
    const actualWidthToReturn = maxWidthToReturn - (maxWidthToReturn % maxSide);
    console.log("actualWidthToReturn", actualWidthToReturn);
    return actualWidthToReturn / maxSidePixels;
  }
  // large desktop
  if (maxInnerSide > 1921 && minInnerSide > 1100) {
    // make sure there is no remainder when dividing the max width by the max side of the image
    const maxWidthToReturn = 780;
    const actualWidthToReturn = maxWidthToReturn - (maxWidthToReturn % maxSide);
    console.log("actualWidthToReturn", actualWidthToReturn);
    return actualWidthToReturn / maxSidePixels;
  }
  //everything else
  const maxWidthToReturn = 460;
  const actualWidthToReturn = maxWidthToReturn - (maxWidthToReturn % maxSide);
  console.log("actualWidthToReturn", actualWidthToReturn);
  return actualWidthToReturn / maxSidePixels;
};
