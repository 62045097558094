/* eslint-disable import/no-anonymous-default-export */
import headshots from "./headshots";
import groupShots from "./group-shots";
import magazineCovers from "./magazine-covers";
import misc from "./misc";
import testing from "./testing";

export default {
  headshots: {
    name: "Free 8x10 Headshot Templates",
    description:
      "Choose from one of the different headshot templates we have below, add your image, crop it as needed, add your text and then download a high res file.",
    templates: headshots,
  },
  misc: {
    name: "Miscellaneous Templates",
    description:
      "These templates are just to do some basic cropping, like if you need to crop to an 8x10 or 11x14.",
    templates: misc,
  },
  // group_shots: {
  //   name: "Group Shot Templates",
  //   description: "Something",
  //   templates: groupShots,
  // },
  // magazine_covers: {
  //   name: "Magazine Cover Templates",
  //   description:
  //     "Add your photo to a cool magazine cover with some custom text.",
  //   templates: magazineCovers,
  // },
  testing: {
    name: "testing",
    description: "Template testing",
    templates: testing,
  },
};
