import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import Box from "@material-ui/core/Box";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./containers/home";
import Editor from "./containers/editor";
import About from "./containers/about";
import Help from "./containers/help";
import OrderPrints from "./containers/orderprints";
import Tracker from "./components/Tracker";
import Test from "containers/test";

ReactGA.initialize("UA-1880575-19");

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn:
      "https://a4dde7e39e2f4a25ad2e9cea52fd3f2a@o49126.ingest.sentry.io/5758048",
    defaultIntegrations: false,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

function App() {
  return (
    <Router>
      <div className="App">
        <Helmet />
        <Tracker />
        <Navbar />
        <Box flex={1}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/editor/:category/:id" component={Editor} />
            <Route exact path="/about" component={About} />
            <Route exact path="/order-prints" component={OrderPrints} />
            <Route exact path="/help" component={Help} />
            <Route exact path="/test" component={Test} />
          </Switch>
        </Box>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
