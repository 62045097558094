import { Helmet } from "react-helmet";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { Link } from "@material-ui/core";

const About = () => {
  return (
    <Box marginTop={6}>
      <Helmet title="About Templates.Qprints.com">
        <meta
          name="description"
          content="The free templates here are courtesy of Qprints.com."
        />
      </Helmet>
      <Container>
        <Typography variant="h1">About our Templates</Typography>
        <Typography paragraph>
          These templates for 8x10 headshots and a few other things are provided
          by Qprints.com - which specializes in{" "}
          <a target="_blank" rel="noopener" href="https://qprints.com">
            low cost 8x10 printing
          </a>{" "}
          on traditional photo paper. The templates are designed to create a
          high resolution file at 300 ppi (pixels per inch).
        </Typography>
        <Typography paragraph>
          This website was created as a proof of concept for our ordering system
          - the Simple Order System. While this website should work well under
          most circumstances, there are some bugs occasionally and we are no
          longer adding new templates.
        </Typography>
        <Typography component="address">
          Qprints Photo Lab
          <br />
          6370 El Cajon blvd
          <br />
          San Diego, CA 92115 <br />
          Email: <a href="mailto:orders@qprints.com">orders@qprints.com</a>
          <br />
          <a href="https://qprints.com/" target="_blank" rel="noopener">
            Qprints.com website
          </a>
        </Typography>
      </Container>
    </Box>
  );
};

export default About;
