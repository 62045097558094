import {
  AppBar,
  Container,
  Grid,
  makeStyles,
  Toolbar,
} from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import React from "react";
import Logo from "templates-qprints-logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#f9f9f9",
    borderBottom: "1px solid #dcdcdc",
    marginBottom: "0rem",
  },
  navLogo: {
    [theme.breakpoints.down("sm")]: {
      margin: "10px auto 0",
    },
  },
  navLinks: {
    paddingTop: "10px",
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto 10px",
    },
  },
  navLink: {
    [theme.breakpoints.down("sm")]: {
      padding: "10px 14px",
    },
    textDecoration: "none",
    fontSize: "1rem",
    fontWeight: "bold",
    color: "#555",
    padding: "0 .7rem",
    "&:hover": {
      color: "#1986f3",
    },
  },
  navItem: {},
  active: {
    color: "#1986f3",
  },
}));

export default function Navbar() {
  const location = useLocation();
  const MenuItems = [
    {
      text: "Home",
      path: "/",
    },
    {
      text: "Order Prints",
      path: "/order-prints",
    },
    {
      text: "About",
      path: "/about",
    },
  ];
  const classes = useStyles();

  return (
    <AppBar position="relative" className={classes.root} elevation={0}>
      <Container>
        <Toolbar disableGutters>
          <Grid container justify="space-between">
            <Grid item key="logo" className={classes.navLogo}>
              <Link to="/">
                <img src={Logo} alt="Logo" />
              </Link>
            </Grid>
            <Grid item key="links" className={classes.navLinks}>
              <Grid container justify="center">
                {MenuItems.map((item) => (
                  <Grid item className={classes.navLink} key={item.text}>
                    <Link
                      to={item.path}
                      key={item.text}
                      className={`${classes.navLink} ${
                        location.pathname === item.path ? classes.active : null
                      }`}
                    >
                      {item.text}
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
