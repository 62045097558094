import { useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

export default () => {
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;
    trackPage(pathname);
  }, [location]);

  const trackPage = (page) => {
    ReactGA.set({
      page,
    });
    ReactGA.pageview(page);
  };

  return null;
};
