import axios from "axios";

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const uploadImage = async (file) => {
  const data = await client
    .get("signed-url", { params: { type: file.type, name: file.name } })
    .then((resp) => resp.data);

  return axios
    .put(data.url, file, {
      headers: {
        "Content-Type": file.type,
      },
    })
    .then(() => data);
};

export const generateImage = (config) => {
  return client.post("generate", config).then((resp) => resp.data);
};
