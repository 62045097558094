import { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import cloneDeep from "lodash/cloneDeep";
import pick from "lodash/pick";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import TEMPLATES from "templates";
import ImageSettings from "components/ImageSettings";
import TextSettings from "components/TextSettings";
import RotateSettings from "components/RotateSettings";
import TemplateCard from "components/TemplateCard";
import Canvas from "components/Canvas";
import ColorInput from "../../components/ColorInput";
import { generateImage, uploadImage } from "../../api";
import { getRotationFromImage, getZoom } from "utils";
import * as Sentry from "@sentry/react";
import ReactGA from "react-ga";
import Hidden from "@material-ui/core/Hidden";

const drawerWidth = 180;
const settingsWidth = 330;
const TXT_NODE_LIST = [
  "text",
  "fontFamily",
  "fontColor",
  "fontWeight",
  "align",
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minHeight: "calc(100vh - 88px)",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    position: "relative",
  },
  drawerPaper: {
    width: drawerWidth,
    position: "absolute",
    backgroundColor: "transparent",
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    // padding: theme.spacing(3),

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  templatesList: {
    padding: theme.spacing(2),
  },
  settingsPane: {
    position: "relative",
    maxWidth: 400,
    flexShrink: 0,
  },
  settingsPaper: {
    width: settingsWidth,
    position: "absolute",
    top: 0,
  },
  input: {
    display: "none",
  },
}));

const Editor = ({
  match: {
    params: { category, id },
  },
  history,
}) => {
  const classes = useStyles();
  const cropperRef = useRef(null);
  const [template, setTemplate] = useState();
  const [saving, setSaving] = useState();
  const [zoom, setZoom] = useState(0);

  useEffect(() => {
    const group = TEMPLATES[category];
    if (group) {
      const tmpl = group.templates.find((i) => i.id === id);
      const zoomRatio = (tmpl.zoomRatio = getZoom(tmpl));
      setTemplate((prev) => {
        if (!prev) {
          const newTmpl = cloneDeep(tmpl);
          newTmpl.zoomRatio = zoomRatio;
          return newTmpl;
        }
        const newTmpl = cloneDeep(tmpl);
        newTmpl.zoomRatio = zoomRatio;
        newTmpl.nodes.forEach((node, idx) => {
          const prevNode = prev.nodes[idx];
          if (prevNode) {
            if (node.type === "text") {
              Object.assign(node, pick(prevNode, TXT_NODE_LIST));
            } else {
              node.image = prevNode.image;
            }
          }
        });
        return newTmpl;
      });
    }
  }, [id]);

  const onTemplateSelect = (t) => {
    history.push(`/editor/${category}/${t.id}`);
  };
  //cropping details for troubleshooting
  const details = () => {
    const imageElement2 = cropperRef?.current;
    const cropper2 = imageElement2?.cropper;
    const canvData2 = cropper2.getCanvasData();
    const top = Math.floor(canvData2.top / getZoom(template));
    const left = Math.floor(canvData2.left / getZoom(template));
    const width = Math.ceil(canvData2.width / getZoom(template));
    const height = Math.ceil(canvData2.height / getZoom(template));
    const items = [{ top, left, width, height }];
    console.log(`items`, items);
    return items;
  };

  const updateNode = (node) => {
    template.nodes = template.nodes.map((i) => (i === node ? cloneDeep(i) : i));
    setTimeout(() => {
      setTemplate({ ...template });
    }, 0);
  };

  const onRotate = (val) => {
    console.log(`val in onRotate`, val);
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    const imgNode = template.nodes[0];
    cropper.rotate(val);
    const imageData = cropper.getImageData();
    imgNode.rotate = imageData.rotate;
    updateNode(imgNode);
    if (val !== -1 && val !== 1) {
      setTimeout(() => {
        cropper.zoomTo(0);
      }, 0);
    }
  };

  const onBackgroundColorChange = (val) => {
    setTemplate({
      ...template,
      backgroundColor: val,
    });
  };
  //file select for Change Image button
  const onFileSelect = (e) => {
    const { files } = e.target;
    const file = files[0];
    const imgNode = template.nodes[0];
    imgNode.image = file;
    imgNode.rotate = 0;
    updateNode(imgNode);
    if (template.nodes.length === 1) {
      getRotationFromImage(file, imgNode).then((rotate) => {
        if (rotate) {
          onCanvasRotate(rotate);
        }
      });
    }
  };

  const onChangeFitImage = (e) => {
    const { checked } = e.target;
    template.fitImage = checked;
    setTemplate({
      ...template,
    });
  };

  const onCanvasRotate = (rotate) => {
    console.log("rotate canvas");
    console.log(`template - in onCanvasRotate`, template);
    const { width, height } = template;
    template.width = height;
    template.height = width;
    setTemplate({
      ...template,
    });
    template.zoomRatio = getZoom(template);
    const imgNode = template.nodes[0];
    const { width: imgWidth, height: imgHeight } = imgNode;
    imgNode.width = imgHeight;
    imgNode.height = imgWidth;
    updateNode(imgNode);
    //onRotate(rotate);
  };

  const onDownload = async () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    const canvData = cropper.getCanvasData();
    const image = cropper.getImageData();
    const cropbox = cropper.getCropBoxData();
    const zoomRatio = getZoom(template);
    console.log({ image, canvData, cropbox });
    console.log("canvData", canvData);
    console.log(cropbox);

    const imgNode = template.nodes[0];
    imgNode.crop = {
      top: Math.floor(canvData.top / zoomRatio),
      left: Math.floor(canvData.left / zoomRatio),
    };
    imgNode.canvas = {
      width: Math.ceil(canvData.width / zoomRatio),
      height: Math.ceil(canvData.height / zoomRatio),
    };
    imgNode.rotate = image.rotate;
    const payload = {
      ...template,
    };

    setSaving(true);
    try {
      const { key } = await uploadImage(imgNode.image);
      payload.key = key;
      payload.filename = imgNode.image.name;
      console.log(payload);

      const resp = await generateImage(payload);
      window.location.replace(resp.url);
      ReactGA.event({
        category: "Template Downloaded",
        action: `${category} - ${payload.name}`,
      });
    } catch (e) {
      Sentry.captureException(e);
      alert(e.error || e.message);
    } finally {
      setSaving(false);
      Sentry.captureMessage("Template downloaded");
    }
  };

  const group = TEMPLATES[category];

  return (
    <Box className={classes.root}>
      <Helmet title="Qprints Template Editor">
        <meta
          name="description"
          content="Choose a template, upload an image, add some text - and download a free high res digital file."
        />
      </Helmet>

      <Hidden smDown>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="left"
        >
          <Box className={classes.templatesList}>
            {group.templates.map((i) => (
              <TemplateCard
                key={i.id}
                template={i}
                isSelected={i.id === template?.id}
                onSelect={onTemplateSelect}
              />
            ))}
          </Box>
        </Drawer>
      </Hidden>
      <Box className={classes.content}>
        {template && (
          <Canvas
            cropperRef={cropperRef}
            template={template}
            onUpdateNode={updateNode}
            onZoom={setZoom}
            onCanvasRotate={onCanvasRotate}
          />
        )}
        {template && template.nodes[0].image && (
          <RotateSettings onRotate={onRotate} fit={template.fitImage} />
        )}
      </Box>
      {template && template.nodes[0].image && (
        <Box className={classes.settingsPane}>
          <Box padding={2}>
            <Typography variant="h5" align="center" color="textPrimary">
              Settings
            </Typography>
            {template && (
              <Box>
                <TextSettings
                  onChange={updateNode}
                  nodes={template.nodes.filter((i) => i.type === "text")}
                />
                <Box>
                  <ImageSettings
                    onChange={updateNode}
                    nodes={template.nodes.filter((i) => i.type === "image")}
                  />
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  marginBottom={2}
                >
                  <Box marginRight={2} display="flex" alignItems="center">
                    <Typography variant="body2" style={{ marginRight: 6 }}>
                      Background Color:{" "}
                    </Typography>
                    <ColorInput
                      value={template.backgroundColor}
                      onChange={onBackgroundColorChange}
                    />
                  </Box>
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={template.fitImage || false}
                          onChange={onChangeFitImage}
                        />
                      }
                      label="Fit Image"
                      title="This will shrink your image down so that the entire image fits in the box, with borders on the sides. Border color will be the background color."
                    />
                  </Box>
                </Box>
                <Box marginBottom={2}>
                  <label htmlFor="contained-button-file">
                    <Button fullWidth variant="outlined" component="span">
                      Change Image
                    </Button>
                  </label>
                  <input
                    onChange={onFileSelect}
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    type="file"
                  />
                </Box>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={onDownload}
                  disabled={saving || !template.nodes[0]?.image}
                >
                  {saving ? (
                    <>
                      <CircularProgress size={24} /> Generating..
                    </>
                  ) : (
                    "Download"
                  )}
                </Button>
                <Box mt={4}>
                  Need 8x10 or 11x14 prints?
                  <br />
                  Try{" "}
                  <a target="_blank" rel="noopener" href="https://qprints.com">
                    Qprints.com low cost 8x10 printing
                  </a>
                </Box>
                {zoom / getZoom(template) > 1.4 && (
                  <Box mt={1}>
                    <Alert severity="warning">
                      <AlertTitle>Resolution Warning</AlertTitle>
                      Your image is{" "}
                      {Math.floor(300 / (zoom / getZoom(template)))} ppi at this
                      zoom. <br />
                      The minimum resolution required for a high quality print
                      is around 200 ppi. It may be ok for your purposes but we
                      wanted to warn you. Anything below 150 ppi will be
                      noticeably fuzzy.
                      <br /> Any text added by our template will be high
                      resolution regardless of the image quality.
                    </Alert>
                  </Box>
                )}
                {/* <Typography paragraph>Data:</Typography>
                <Typography variant="caption" component="p">
                  Cropper Zoom: {zoom}
                </Typography>
                <Typography variant="caption" component="p">
                  getZoom: {getZoom(template)}
                </Typography>
                <Typography variant="caption" component="p">
                  normalized zoom: {zoom / getZoom(template)}
                </Typography>
                <Typography variant="caption" component="p">
                  ppi: {Math.floor(300 / (zoom / getZoom(template)))}
                </Typography>
                <Typography variant="caption" component="p">
                  Rotation:{" "}
                  {template.nodes[0].rotate ? template.nodes[0].rotate : "?"}
                </Typography>

                {zoom && (
                  <Typography variant="caption" component="p">
                    {details().map((item) => (
                      <>
                        Top: {item.top}
                        <br />
                        Left: {item.left}
                        <br />
                        Width: {item.width}
                        <br />
                        Height: {item.height}
                      </>
                    ))}
                  </Typography>
                )} */}
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Editor;
