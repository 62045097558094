import { useState } from "react";
import { SketchPicker } from "react-color";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  cover: {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  },
  swatch: {
    background: (props) => props.color,
    borderRadius: 4,
    border: "1px solid #ccc",
    display: "inline-block",
    cursor: "pointer",
    width: 28,
    height: 24,
  },
  popover: {
    position: "absolute",
    zIndex: 2,
    left: 0,
  },
}));

const ColorInput = ({ value, onChange }) => {
  const classes = useStyles({ color: value });
  const [show, setShow] = useState(false);
  const handleChangeComplete = (color) => {
    onChange(color.hex);
  };

  const toggle = () => setShow(!show);

  return (
    <Box className={classes.swatch} onClick={toggle}>
      {show && (
        <Box className={classes.popover}>
          <Box className={classes.cover} onClick={toggle} />
          <SketchPicker
            disableAlpha
            color={value}
            onChange={handleChangeComplete}
          />
        </Box>
      )}
    </Box>
  );
};

export default ColorInput;
