import { useEffect } from "react";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import cx from "classnames";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  template: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    padding: "4px",
    marginBottom: theme.spacing(2),
    textAlign: "center",
    border: "2px solid transparent",
    backgroundColor: "#f2f2f2",

    "& img": {
      width: "100%",
      height: "auto",
      marginBottom: "6px",
      border: "1px solid #c7c7c7",
      objectFit: "contain",
    },
    "&.selected": {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const TemplateCard = ({ template, isSelected, onSelect }) => {
  const classes = useStyles();

  useEffect(() => {
    if (isSelected) {
      const el = document.getElementById(`tmpl-${template.id}`);
      el.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [isSelected]);

  const onClick = () => {
    if (onSelect) {
      onSelect(template);
    }
  };

  return (
    <Box
      id={`tmpl-${template.id}`}
      component={Paper}
      className={cx(classes.template, { selected: isSelected })}
      onClick={onClick}
    >
      {template.preview ? (
        <img src={template.preview} />
      ) : (
        <img
          src={`https://via.placeholder.com/240x300.png?text=${template.name}`}
        />
      )}
      <Typography variant="subtitle2">{template.name}</Typography>
    </Box>
  );
};

export default TemplateCard;
