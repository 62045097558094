export default [
  { label: "Open Sans", value: "Open Sans" },
  { label: "Roboto", value: "Roboto" },
  { label: "Amiko **", value: "Amiko" },
  { label: "Archivo", value: "Archivo" },
  { label: "Merriweather Serif", value: "Merriweather" },
  { label: "Times New Roman", value: "Times New Roman" },
  { label: "Comfortaa **", value: "Comfortaa" },
  { label: "Special Elite *", value: "Special Elite" },
  { label: "Zilla Slab", value: "Zilla Slab" },
  { label: "Allura Script *", value: "Allura" },
  { label: "Dancing Script **", value: "Dancing Script" },
  { label: "Give You Glory Script *", value: "Give You Glory" },
];
