import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import MuiLink from "@material-ui/core/Link";
import testing from "../../templates/testing";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  item: {
    textAlign: "center",
    "& img": {
      width: "100%",
      height: "auto",
      objectFit: "contain",
      border: "1px solid #a7a7a7",
    },
  },
}));

const Test = () => {
  const classes = useStyles();

  return (
    <Box marginTop={6}>
      <Helmet title="Qprints Free Headshot Templates">
        <meta
          name="description"
          content="Use our template generator to download free high res headshot templates with your image and custom text."
        />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Container>
        <Grid container spacing={2}>
          {testing.map((t) => (
            <Grid item key={t.id} xs={12} sm={4} md={3}>
              <Box className={classes.item} marginBottom={2}>
                <Link to={`/editor/testing/${t.id}`}>
                  {t.preview ? (
                    <img src={t.preview} alt={`${t.name}`} />
                  ) : (
                    <img
                      src={`https://via.placeholder.com/240x300.png?text=${t.name}`}
                      alt={`${t.name}`}
                    />
                  )}
                </Link>
                <Typography color="textPrimary">{t.name}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Test;
