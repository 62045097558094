/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    id: "HkoJXP8ox_",
    name: "Rectangle",
    product_code: "locket",
    price: 8.95,
    preview: "",
    width: 2.5,
    height: 3,
    backgroundColor: "white",
    nodes: [
      {
        type: "image",
        src: "",
        x: 0,
        y: 0,
        width: 2.5,
        height: 3,
        borderWidth: 0,
        borderColor: "#AAA",
      },
    ],
  },
  {
    id: "HkoJXP8ox3",
    name: "testing heart",
    product_code: "locket",
    price: 8.95,
    preview: "",
    width: 2.5,
    height: 3,
    backgroundColor: "white",
    overlay: {
      placement: "bottom",
      x: 0,
      y: 0,
      width: 2.5,
      height: 3,
      opacity: 1,
      source: "assets/overlay/heart-server.png",
      webOverlay: "/web-overlays/heart-red.png",
    },
    nodes: [
      {
        type: "image",
        src: "",
        x: 0,
        y: 0,
        width: 2.5,
        height: 3,
        borderWidth: 0,
        borderColor: "#AAA",
      },
    ],
  },
  {
    id: "HkoJXP8ox2",
    name: "testing circle",
    product_code: "locket",
    price: 8.95,
    preview: "",
    width: 2.5,
    height: 3,
    backgroundColor: "white",
    overlay: {
      placement: "bottom",
      x: 0,
      y: 0,
      width: 2.5,
      height: 3,
      opacity: 1,
      source: "assets/overlay/circle-red.png",
      webOverlay: "/web-overlays/circle-red.png",
    },
    nodes: [
      {
        type: "image",
        src: "",
        x: 0,
        y: 0,
        width: 2.5,
        height: 3,
        borderWidth: 0,
        borderColor: "#AAA",
      },
    ],
  },
  {
    id: "HkoJXP8ox1",
    name: "testing oval",
    product_code: "locket",
    price: 8.95,
    preview: "",
    width: 2.5,
    height: 3,
    backgroundColor: "white",
    overlay: {
      placement: "bottom",
      x: 0,
      y: 0,
      width: 2.5,
      height: 3,
      opacity: 1,
      source: "assets/overlay/oval-red.png",
      webOverlay: "/web-overlays/oval-red.png",
    },
    nodes: [
      {
        type: "image",
        src: "",
        x: 0,
        y: 0,
        width: 2.5,
        height: 3,
        borderWidth: 0,
        borderColor: "#AAA",
      },
    ],
  },
];
