import { useMemo, useRef, useCallback, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ImageNode from "./ImageNode";
import TextNode from "./TextNode";
import { inchToPixels, getRotationFromImage } from "../utils";

const getOverlayBackground = (template) => {
  const { overlay } = template;
  if (overlay) {
    const { x, y, webOverlay } = overlay;
    return `transparent url(${webOverlay}) no-repeat scroll ${inchToPixels(
      y
    )}px ${inchToPixels(x)}px`;
  }
};

const getOverlaySize = (template) => {
  if (template.overlay) {
    const { zoomRatio, overlay } = template;
    const { width, height } = overlay;
    return `${inchToPixels(width * zoomRatio)}px ${inchToPixels(
      height * zoomRatio
    )}px`;
  }
  return undefined;
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  input: {
    display: "none",
  },
  canvas: {
    position: "relative",
    width: ({ width, zoomRatio }) => inchToPixels(width) * zoomRatio,
    height: ({ zoomRatio, height }) => inchToPixels(height) * zoomRatio,
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    display: "table",
    margin: "auto",
    border: "1px solid silver",
    "&:after": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      background: getOverlayBackground,
      backgroundSize: getOverlaySize,
      pointerEvents: "none",
    },
  },
  textNode: {
    border: "2px solid",
    borderColor: "#DDD",
  },
}));

const Canvas = ({
  template,
  onUpdateNode,
  cropperRef,
  onZoom,
  onCanvasRotate,
}) => {
  const imgNode = template.nodes[0];
  const classes = useStyles(template);

  useEffect(() => {
    if (cropperRef.current) {
      cropperRef.current.cropper.setCropBoxData({
        viewMode: template.fitImage === true ? 2 : 3,
      });
    }
  }, [template.fitImage]);
  //file select for Select Image - (empty template)
  const onFileSelect = (e) => {
    const { files } = e.target;
    const file = files[0];
    imgNode.image = file;
    onUpdateNode(imgNode);
    if (template.nodes.length === 1 && !template.overlay) {
      getRotationFromImage(file, imgNode).then((rotate) => {
        if (rotate) {
          onCanvasRotate(rotate);
        }
      });
    }
  };

  return (
    <Box>
      {!imgNode.image ? (
        <Box margin="auto" maxWidth={200}>
          <label htmlFor="contained-button-file">
            <Button
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              component="span"
            >
              Select Image
            </Button>
          </label>
          <input
            onChange={onFileSelect}
            accept="image/*"
            className={classes.input}
            id="contained-button-file"
            type="file"
          />
        </Box>
      ) : (
        <Box className={classes.root}>
          <Box className={classes.canvas} component={Paper}>
            <ImageNode
              key={template.id + template.fitImage}
              node={imgNode}
              overlay={template.overlay}
              cropperRef={cropperRef}
              fitImage={template.fitImage}
              onZoom={onZoom}
              zoomRatio={template.zoomRatio}
            />
            <Box>
              {template.nodes
                .filter((x) => x.type === "text")
                .map((i, idx) => (
                  <TextNode node={i} key={idx} zoomRatio={template.zoomRatio} />
                ))}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Canvas;
