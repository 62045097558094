/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    id: "m-1",
    name: "Normal 8x10",
    preview: "/previews/8x10.png",
    width: 8,
    height: 10,
    backgroundColor: "white",
    nodes: [
      {
        type: "image",
        src: "",
        x: 0,
        y: 0,
        width: 8,
        height: 10,
      },
    ],
  },
  {
    id: "m-2",
    name: "8x10 with White Border",
    preview: "/previews/8x10wb.png",
    width: 8,
    height: 10,
    backgroundColor: "white",
    nodes: [
      {
        type: "image",
        src: "",
        x: 0.3,
        y: 0.3,
        width: 7.4,
        height: 9.4,
      },
    ],
  },
  {
    id: "m-3",
    name: "8x10 Force Fit",
    preview: "/previews/8x10-fit.png",
    width: 8,
    height: 10,
    backgroundColor: "white",
    fitImage: true,
    nodes: [
      {
        type: "image",
        src: "",
        x: 0,
        y: 0,
        width: 8,
        height: 10,
      },
    ],
  },
  {
    id: "m-4",
    name: "Normal 11x14",
    preview: "/previews/11x14.png",
    width: 11,
    height: 14,
    backgroundColor: "white",
    nodes: [
      {
        type: "image",
        src: "",
        x: 0,
        y: 0,
        width: 11,
        height: 14,
      },
    ],
  },
  // {
  //   id: "m-5w",
  //   name: "Wallet with Rotated Text in Corner - Vertical",
  //   preview: "/previews/wallet-with-text.png",
  //   width: 2.5,
  //   height: 3.5,
  //   backgroundColor: "white",
  //   nodes: [
  //     {
  //       type: "image",
  //       src: "",
  //       x: 0,
  //       y: 0,
  //       width: 2.5,
  //       height: 3.5,
  //       borderWidth: 1,
  //       borderColor: "#AAA",
  //       rotate: 0,
  //     },
  //     {
  //       type: "text",
  //       caption: "Line 1:",
  //       text: "Jake",
  //       x: 1.48,
  //       y: 2.67,
  //       width: 0.9,
  //       height: 0.2,
  //       fontSize: 50,
  //       fontFamily: "Times New Roman",
  //       fontColor: "#fff",
  //       fontWeight: "normal",
  //       fontStyle: "italic",
  //       dropShadow: 1,
  //       align: "center",
  //       rotate: -25,
  //     },
  //     {
  //       type: "text",
  //       caption: "Line 2:",
  //       text: "2021",
  //       x: 1.55,
  //       y: 2.8,
  //       width: 0.9,
  //       height: 0.2,
  //       fontSize: 50,
  //       fontFamily: "Times New Roman",
  //       fontColor: "#fff",
  //       fontWeight: "normal",
  //       fontStyle: "italic",
  //       dropShadow: 1,
  //       align: "center",
  //       rotate: -25,
  //     },
  //   ],
  // },
];
