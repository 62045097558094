import cx from "classnames";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormatBoldIcon from "@material-ui/icons/FormatBold";
import FormatItalicIcon from "@material-ui/icons/FormatItalic";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import FormatAlignRightIcon from "@material-ui/icons/FormatAlignRight";
import FormatAlignCenterIcon from "@material-ui/icons/FormatAlignCenter";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ColorInput from "./ColorInput";
import FONTS from "../constants/fonts";

const useStyles = makeStyles((theme) => ({
  btn: {
    backgroundColor: "transparent",
    borderRadius: 4,
    border: "1px solid #ccc",
    padding: "3px 5px",
    lineHeight: 0,
    marginRight: 4,
    cursor: "pointer",
    "& svg": {
      fontSize: 16,
    },
    "&.selected": {
      backgroundColor: "#ccc",
    },
  },
}));

const TextNode = ({ node, onChange, index }) => {
  const classes = useStyles();
  const onTextChange = (e) => {
    const { value } = e.target;
    node.text = value;
    onChange(node);
  };

  const onColorChange = (val) => {
    node.fontColor = val;
    onChange(node);
  };

  const onFontFamilyChange = (e) => {
    const { value } = e.target;
    node.fontFamily = value;
    onChange(node);
  };

  const onFontWeightChange = () => {
    node.fontWeight = node.fontWeight === "bold" ? "normal" : "bold";
    onChange(node);
  };

  const onFontStyleChange = () => {
    node.fontStyle = node.fontStyle === "italic" ? "normal" : "italic";
    onChange(node);
  };

  const onJustify = (val) => {
    node.align = val;
    onChange(node);
  };

  const onShadowChange = (e) => {
    const { checked } = e.target;
    node.enableShadow = checked;
    onChange(node);
  };

  return (
    <Box border={1} padding={1} marginBottom={1}>
      <Typography>{node.caption}</Typography>
      <FormControl fullWidth margin="dense">
        <TextField
          size="small"
          variant="outlined"
          placeholder="Type your text"
          value={node.text}
          onChange={onTextChange}
        />
      </FormControl>
      <FormControl fullWidth margin="dense">
        <Select
          size="small"
          variant="outlined"
          value={node.fontFamily}
          onChange={onFontFamilyChange}
        >
          <MenuItem value="" disabled>
            Font
          </MenuItem>
          {FONTS.map((i, idx) => (
            <MenuItem key={idx} value={i.value} style={{ fontFamily: i.value }}>
              {i.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box display="flex" alignItems="center" marginTop={1}>
        <button
          className={cx(classes.btn, { selected: node.fontWeight === "bold" })}
          onClick={onFontWeightChange}
        >
          <FormatBoldIcon />
        </button>
        <button
          className={cx(classes.btn, {
            selected: node.fontStyle === "italic",
          })}
          onClick={onFontStyleChange}
        >
          <FormatItalicIcon />
        </button>
        <Divider flexItem orientation="vertical" style={{ marginRight: 4 }} />
        <button
          className={cx(classes.btn, {
            selected: node.align === "left",
          })}
          onClick={() => onJustify("left")}
        >
          <FormatAlignLeftIcon />
        </button>
        <button
          className={cx(classes.btn, {
            selected: node.align === "center",
          })}
          onClick={() => onJustify("center")}
        >
          <FormatAlignCenterIcon />
        </button>
        <Divider flexItem orientation="vertical" style={{ marginRight: 4 }} />
        <button
          className={cx(classes.btn, {
            selected: node.align === "right",
          })}
          onClick={() => onJustify("right")}
        >
          <FormatAlignRightIcon />
        </button>
        <ColorInput value={node.fontColor} onChange={onColorChange} />
      </Box>
      <Box marginTop={1}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={node.enableShadow || false}
              onChange={onShadowChange}
            />
          }
          label="Apply Text shadow"
          title="Needed mostly when white text is directly over the image, a shadow will make it show up better"
        />
      </Box>
    </Box>
  );
};

const TextSettings = ({ nodes, onChange }) => {
  return (
    <Box marginBottom={2}>
      {nodes.map((node, i) => (
        <TextNode key={i} node={node} onChange={onChange} index={i} />
      ))}
    </Box>
  );
};

export default TextSettings;
