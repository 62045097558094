/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    id: "tc-1",
    name: "#1 Classic",
    preview: "/previews/8x10-headshot-prints-template-01.png",
    width: 8,
    height: 10,
    backgroundColor: "white",
    nodes: [
      {
        type: "image",
        src: "",
        x: 0,
        y: 0,
        width: 8,
        height: 9,
        borderWidth: 1,
        borderColor: "#AAA",
        rotate: 0,
      },
      {
        type: "text",
        caption: "Enter Name:",
        text: "",
        x: 0.25,
        y: 9.18,
        width: 7.5,
        height: 0.5,
        fontSize: 120,
        fontFamily: "Open Sans",
        fontColor: "#000000",
        fontWeight: "bold",
        dropShadow: 1,
        align: "center",
      },
    ],
  },
  {
    id: "tc-2",
    name: "#2 Classic with Border",
    preview: "/previews/8x10-headshot-prints-template-02.png",
    width: 8,
    height: 10,
    backgroundColor: "white",
    nodes: [
      {
        type: "image",
        src: "",
        x: 0.3,
        y: 0.3,
        width: 7.4,
        height: 8.5,
        borderWidth: 1,
        borderColor: "#AAA",
        rotate: 0,
      },
      {
        type: "text",
        caption: "Enter Name:",
        text: "",
        x: 0.3,
        y: 9.1,
        width: 7.4,
        height: 0.5,
        fontSize: 120,
        fontFamily: "Open Sans",
        fontColor: "#000000",
        fontWeight: "bold",
        dropShadow: 2,
        align: "center",
      },
    ],
  },
  {
    id: "tc-3",
    name: "#3 Classic with Contact",
    preview: "/previews/8x10-headshot-prints-template-03.png",
    width: 8,
    height: 10,
    backgroundColor: "white",
    nodes: [
      {
        type: "image",
        src: "",
        x: 0,
        y: 0,
        width: 8,
        height: 9,
        borderWidth: 2,
        borderColor: "#a7a7a7",
        rotate: 0,
      },
      {
        type: "text",
        caption: "Enter Name:",
        text: "",
        x: 0.25,
        y: 9.1,
        width: 7.5,
        height: 0.5,
        fontSize: 100,
        fontFamily: "Open Sans",
        fontColor: "#000000",
        fontWeight: "bold",
        dropShadow: 1,
        align: "center",
      },
      {
        type: "text",
        caption: "Enter Contact Info:",
        text: "",
        x: 0.25,
        y: 9.56,
        width: 7.5,
        height: 0.2,
        fontSize: 50,
        fontFamily: "Open Sans",
        fontColor: "#000000",
        fontWeight: "bold",
        dropShadow: 1,
        align: "center",
      },
    ],
  },
  {
    id: "tc-4",
    name: "#4 Classic with Border and Contact",
    preview: "/previews/8x10-headshot-prints-template-04.png",
    width: 8,
    height: 10,
    backgroundColor: "white",
    nodes: [
      {
        type: "image",
        src: "",
        x: 0.3,
        y: 0.3,
        width: 7.4,
        height: 8.7,
        borderWidth: 2,
        borderColor: "#a7a7a7",
        rotate: 0,
      },
      {
        type: "text",
        caption: "Enter Name:",
        text: "",
        x: 0.3,
        y: 9.1,
        width: 7.4,
        height: 0.5,
        fontSize: 100,
        fontFamily: "Open Sans",
        fontColor: "#000000",
        fontWeight: "bold",
        dropShadow: 1,
        align: "center",
      },
      {
        type: "text",
        caption: "Enter Contact Info:",
        text: "",
        x: 0.25,
        y: 9.55,
        width: 7.5,
        height: 0.2,
        fontSize: 50,
        fontFamily: "Open Sans",
        fontColor: "#000000",
        fontWeight: "bold",
        dropShadow: 1,
        align: "center",
      },
    ],
  },
  {
    id: "tc-5",
    name: "#5 Minimal",
    preview: "/previews/8x10-headshot-prints-template-05.png",
    width: 8,
    height: 10,
    backgroundColor: "white",
    nodes: [
      {
        type: "image",
        src: "",
        x: 0,
        y: 0,
        width: 8,
        height: 9.33,
        borderWidth: 2,
        borderColor: "#a7a7a7",
        rotate: 0,
      },
      {
        type: "text",
        caption: "Enter Name:",
        text: "",
        x: 0.25,
        y: 9.45,
        width: 7.5,
        height: 0.4,
        fontSize: 70,
        fontFamily: "Open Sans",
        fontColor: "#000000",
        fontWeight: "bold",
        dropShadow: 1,
        align: "center",
      },
    ],
  },
  {
    id: "tc-6",
    name: "#6 Minimal with Border",
    preview: "/previews/8x10-headshot-prints-template-06.png",
    width: 8,
    height: 10,
    backgroundColor: "white",
    nodes: [
      {
        type: "image",
        src: "",
        x: 0.3,
        y: 0.3,
        width: 7.4,
        height: 9.03,
        borderWidth: 2,
        borderColor: "#a7a7a7",
        rotate: 0,
      },
      {
        type: "text",
        caption: "Enter Name:",
        text: "",
        x: 0.3,
        y: 9.45,
        width: 7.4,
        height: 0.4,
        fontSize: 70,
        fontFamily: "Open Sans",
        fontColor: "#000000",
        fontWeight: "bold",
        dropShadow: 1,
        align: "center",
      },
    ],
  },
  {
    id: "tc-7",
    name: "#7 Minimal with Contact",
    preview: "/previews/8x10-headshot-prints-template-07.png",
    width: 8,
    height: 10,
    backgroundColor: "white",
    nodes: [
      {
        type: "image",
        src: "",
        x: 0,
        y: 0,
        width: 8,
        height: 9.33,
        borderWidth: 2,
        borderColor: "#a7a7a7",
        rotate: 0,
      },
      {
        type: "text",
        caption: "Enter Name:",
        text: "",
        x: 0.3,
        y: 9.45,
        width: 4.8,
        height: 0.4,
        fontSize: 70,
        fontFamily: "Open Sans",
        fontColor: "#000000",
        fontWeight: "bold",
        dropShadow: 1,
        align: "left",
      },
      {
        type: "text",
        caption: "Enter Contact Line 1:",
        text: "",
        x: 5.1,
        y: 9.45,
        width: 2.6,
        height: 0.2,
        fontSize: 35,
        fontFamily: "Open Sans",
        fontColor: "#000000",
        fontWeight: "bold",
        dropShadow: 1,
        align: "right",
      },
      {
        type: "text",
        caption: "Enter Contact Line 2:",
        text: "",
        x: 5.1,
        y: 9.65,
        width: 2.6,
        height: 0.2,
        fontSize: 35,
        fontFamily: "Open Sans",
        fontColor: "#000000",
        fontWeight: "bold",
        dropShadow: 1,
        align: "right",
      },
    ],
  },
  {
    id: "tc-8",
    name: "#8 Minimal with Contact and Border",
    preview: "/previews/8x10-headshot-prints-template-08.png",
    width: 8,
    height: 10,
    backgroundColor: "white",
    nodes: [
      {
        type: "image",
        src: "",
        x: 0.3,
        y: 0.3,
        width: 7.4,
        height: 9.03,
        borderWidth: 2,
        borderColor: "#a7a7a7",
        rotate: 0,
      },
      {
        type: "text",
        caption: "Enter Name:",
        text: "",
        x: 0.3,
        y: 9.45,
        width: 4.8,
        height: 0.4,
        fontSize: 70,
        fontFamily: "Open Sans",
        fontColor: "#000000",
        fontWeight: "bold",
        dropShadow: 1,
        align: "left",
      },
      {
        type: "text",
        caption: "Enter Contact Line 1:",
        text: "",
        x: 5.1,
        y: 9.45,
        width: 2.6,
        height: 0.2,
        fontSize: 35,
        fontFamily: "Open Sans",
        fontColor: "#000000",
        fontWeight: "bold",
        dropShadow: 1,
        align: "right",
      },
      {
        type: "text",
        caption: "Enter Contact Line 2:",
        text: "",
        x: 5.1,
        y: 9.65,
        width: 2.6,
        height: 0.2,
        fontSize: 35,
        fontFamily: "Open Sans",
        fontColor: "#000000",
        fontWeight: "bold",
        dropShadow: 1,
        align: "right",
      },
    ],
  },
  {
    id: "tc-9",
    name: "#9 Full Frame",
    preview: "/previews/8x10-headshot-prints-template-09.png",
    width: 8,
    height: 10,
    backgroundColor: "white",
    nodes: [
      {
        type: "image",
        src: "",
        x: 1.05,
        y: 0.35,
        width: 5.9,
        height: 8.8,
        borderWidth: 1,
        borderColor: "#AAA",
        rotate: 0,
      },
      {
        type: "text",
        caption: "Enter Name:",
        text: "",
        x: 0.25,
        y: 9.25,
        width: 7.5,
        height: 0.6,
        fontSize: 120,
        fontFamily: "Open Sans",
        fontColor: "#000000",
        fontWeight: "bold",
        dropShadow: 1,
        align: "center",
      },
    ],
  },
  {
    id: "tc-10",
    name: "#10 Full Frame with Contact",
    preview: "/previews/8x10-headshot-prints-template-10.png",
    width: 8,
    height: 10,
    backgroundColor: "white",
    nodes: [
      {
        type: "image",
        src: "",
        x: 1.05,
        y: 0.35,
        width: 5.9,
        height: 8.8,
        borderWidth: 1,
        borderColor: "#AAA",
        rotate: 0,
      },
      {
        type: "text",
        caption: "Enter Name:",
        text: "",
        x: 0.25,
        y: 9.2,
        width: 7.5,
        height: 0.45,
        fontSize: 100,
        fontFamily: "Open Sans",
        fontColor: "#000000",
        fontWeight: "bold",
        dropShadow: 1,
        align: "center",
      },
      {
        type: "text",
        caption: "Enter Contact Info:",
        text: "",
        x: 0.25,
        y: 9.65,
        width: 7.5,
        height: 0.2,
        fontSize: 50,
        fontFamily: "Open Sans",
        fontColor: "#000000",
        fontWeight: "bold",
        dropShadow: 1,
        align: "center",
      },
    ],
  },
  {
    id: "tc-11",
    name: "#11 Full Frame with Contact 2",
    preview: "/previews/8x10-headshot-prints-template-11.png",
    width: 8,
    height: 10,
    backgroundColor: "white",
    nodes: [
      {
        type: "image",
        src: "",
        x: 1,
        y: 0.3,
        width: 6,
        height: 9,
        borderWidth: 1,
        borderColor: "#AAA",
        rotate: 0,
      },
      {
        type: "text",
        caption: "Enter Name:",
        text: "",
        x: 1,
        y: 9.4,
        width: 2.95,
        height: 0.45,
        fontSize: 85,
        fontFamily: "Open Sans",
        fontColor: "#000000",
        fontWeight: "bold",
        dropShadow: 1,
        align: "left",
      },
      {
        type: "text",
        caption: "Enter Contact Line 1:",
        text: "",
        x: 4,
        y: 9.4,
        width: 3,
        height: 0.2,
        fontSize: 35,
        fontFamily: "Open Sans",
        fontColor: "#000000",
        fontWeight: "bold",
        dropShadow: 1,
        align: "right",
      },
      {
        type: "text",
        caption: "Enter Contact Line 2:",
        text: "",
        x: 4,
        y: 9.6,
        width: 3,
        height: 0.2,
        fontSize: 35,
        fontFamily: "Open Sans",
        fontColor: "#000000",
        fontWeight: "bold",
        dropShadow: 1,
        align: "right",
      },
    ],
  },
  {
    id: "toi-1",
    name: "#12 Text Over Image Vertical",
    preview: "/previews/hs-12.png",
    width: 8,
    height: 10,
    backgroundColor: "white",
    nodes: [
      {
        type: "image",
        src: "",
        x: 0,
        y: 0,
        width: 8,
        height: 10,
        borderWidth: 1,
        borderColor: "#AAA",
        rotate: 0,
      },
      {
        type: "text",
        caption: "Enter Name:",
        text: "",
        x: 0.5,
        y: 9.2,
        width: 7,
        height: 0.6,
        fontSize: 120,
        fontFamily: "Open Sans",
        fontColor: "white",
        fontWeight: "bold",
        dropShadow: 1,
        align: "center",
      },
    ],
  },
  {
    id: "tc-1H",
    name: "#H1 Classic Horizontal",
    preview: "/previews/8x10-headshot-prints-template-13.png",
    width: 10,
    height: 8,
    backgroundColor: "white",
    nodes: [
      {
        type: "image",
        src: "",
        x: 0,
        y: 0,
        width: 10,
        height: 7.05,
        borderWidth: 3,
        borderColor: "#efefef",
        rotate: 0,
      },
      {
        type: "text",
        caption: "Enter Name:",
        text: "",
        x: 0.5,
        y: 7.15,
        width: 9,
        height: 0.6,
        fontSize: 120,
        fontFamily: "Open Sans",
        fontColor: "#000000",
        fontWeight: "bold",
        dropShadow: 1,
        align: "left",
      },
    ],
  },
  {
    id: "tc-1HWB",
    name: "#H2 Classic Horizontal with Border",
    preview: "/previews/8x10-headshot-prints-template-14.png",
    width: 10,
    height: 8,
    backgroundColor: "white",
    nodes: [
      {
        type: "image",
        src: "",
        x: 0.3,
        y: 0.3,
        width: 9.4,
        height: 6.75,
        borderWidth: 3,
        borderColor: "#efefef",
        rotate: 0,
      },
      {
        type: "text",
        caption: "Enter Name:",
        text: "",
        x: 0.3,
        y: 7.15,
        width: 9.4,
        height: 0.6,
        fontSize: 120,
        fontFamily: "Open Sans",
        fontColor: "#000000",
        fontWeight: "bold",
        dropShadow: 1,
        align: "left",
      },
    ],
  },
];
