import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import MuiLink from "@material-ui/core/Link";
import TEMPLATES from "../../templates";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  item: {
    textAlign: "center",
    "& img": {
      width: "100%",
      height: "auto",
      objectFit: "contain",
      border: "1px solid #a7a7a7",
    },
  },
}));

const Home = () => {
  const classes = useStyles();

  return (
    <Box marginTop={6}>
      <Helmet title="Qprints Free Headshot Templates">
        <meta
          name="description"
          content="Use our template generator to download free high res headshot templates with your image and custom text."
        />
      </Helmet>
      <Container>
        <Box marginBottom={4}>
          <Grid container justify={"left"}>
            <Grid item xs={12}>
              <Typography variant="h1" gutterBottom>
                Free, Easy to Use Templates for Headshots and Group Photos
              </Typography>
              <Typography gutterBottom color="textPrimary">
                Choose a template below to start creating your image. After you
                change the settings and like what you see you can download a
                high res image that is ready to print.
              </Typography>
              <Typography>
                Need prints? Download your free template here and then order
                thru our Simple Order System, click{" "}
                <MuiLink component={Link} to="/order-prints">
                  here for info on ordering 8x10 prints
                </MuiLink>
                .
              </Typography>
              <Typography paragraph>
                This site is provided by{" "}
                <a href="https://qprints.com/" target="_blank" rel="noopener">
                  Qprints.com the home of high quality low cost 8x10 prints{" "}
                </a>
                .
              </Typography>
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
        </Box>
        {Object.keys(TEMPLATES).map((key, idx) => {
          const tmpl = TEMPLATES[key];
          return (
            key !== "testing" && (
              <Box key={key} marginBottom={6}>
                <Typography variant="h6">{tmpl.name}</Typography>
                <Typography paragraph>{tmpl.description}</Typography>
                <Grid container spacing={2}>
                  {tmpl.templates.map((t) => (
                    <Grid item key={t.id} xs={12} sm={4} md={3}>
                      <Box className={classes.item} marginBottom={2}>
                        <Link to={`/editor/${key}/${t.id}`}>
                          {t.preview ? (
                            <img
                              src={t.preview}
                              alt={`${tmpl.name} - ${t.name}`}
                            />
                          ) : (
                            <img
                              src={`https://via.placeholder.com/240x300.png?text=${t.name}`}
                              alt={`${tmpl.name} - ${t.name}`}
                            />
                          )}
                        </Link>
                        <Typography color="textPrimary">{t.name}</Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )
          );
        })}
      </Container>
    </Box>
  );
};

export default Home;
