import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#4E4E4E",
    color: "#efefef",
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root} padding={1}>
      <Typography variant="body2" align="center">
        &copy; {new Date().getFullYear()} qprints.com
      </Typography>
    </Box>
  );
};

export default Footer;
