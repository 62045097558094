import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import Rotate90DegreesCcwIcon from "@material-ui/icons/Rotate90DegreesCcw";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const RotateSettings = ({ onRotate, fit }) => {
  const classes = useStyles();

  const handleRotate = (event, newValue) => {
    onRotate(newValue);
  };

  const onLeft = () => {
    handleRotate(null, -90);
  };

  const onRight = () => {
    handleRotate(null, 90);
  };

  const onLeftSmall = () => {
    handleRotate(null, -1);
  };

  const onRightSmall = () => {
    handleRotate(null, 1);
  };

  return (
    <Box marginBottom={2} marginX="auto">
      <Box display="flex" alignItems="center" justifyContent="center">
        <Tooltip arrow title="Rotate left by 90 degrees">
          <Button
            className={classes.button}
            size="medium"
            variant="contained"
            onClick={onLeft}
            startIcon={<Rotate90DegreesCcwIcon />}
          >
            90°
          </Button>
        </Tooltip>
        <Tooltip arrow title="Rotate left by 1 degree">
          <Button
            className={classes.button}
            size="small"
            variant="contained"
            onClick={onLeftSmall}
            startIcon={<RotateLeftIcon />}
          >
            1°
          </Button>
        </Tooltip>

        <Tooltip arrow title="Rotate right by 1 degree">
          <Button
            className={classes.button}
            size="small"
            variant="contained"
            onClick={onRightSmall}
            startIcon={<RotateRightIcon />}
          >
            1°
          </Button>
        </Tooltip>
        <Tooltip arrow title="Rotate right by 90 degrees">
          <Button
            className={classes.button}
            size="medium"
            variant="contained"
            onClick={onRight}
            startIcon={
              <Rotate90DegreesCcwIcon
                style={{ transform: "rotateY(180deg)" }}
              />
            }
          >
            90°
          </Button>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default RotateSettings;
