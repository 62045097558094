import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { Helmet } from "react-helmet";
import Link from "@material-ui/core/Link";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  table: {
    width: 450,
    border: "1px solid silver",
  },
  header: {
    backgroundColor: "#c7c7c7",
  },
});

const OrderPrints = () => {
  const classes = useStyles();

  return (
    <Box marginTop={6}>
      <Helmet title="How to Order 8x10 Prints">
        <meta
          name="description"
          content="How to order 8x10 prints of your free template thru our main Qprints.com website."
        />
      </Helmet>
      <Container>
        <Typography variant="h1">Ordering 8x10 Prints</Typography>
        <Typography paragraph>
          The templates we offer here are all just free downloads.
        </Typography>
        <Typography paragraph>
          If you would like to order prints you can use the images you download
          here and order them thru our{" "}
          <Link
            href="https://qprints.soslabs.net"
            target="_blank"
            rel="noopener"
          >
            Qprints.com Simple Order System
          </Link>
          .
        </Typography>
        <Typography paragraph>
          Please see our main site for more information:{" "}
          <Link href="https://qprints.com/" target="_blank" rel="noopener">
            Qprints.com - Low Cost 8x10 Prints
          </Link>
          .
        </Typography>
      </Container>
    </Box>
  );
};

export default OrderPrints;
